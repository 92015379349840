import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { generateToken } from './utils/helpers';
import axios from 'axios';

axios.interceptors.request.use((config) => {
  config.headers['ga-hash'] = generateToken(config.data);
  return config;
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('root')
);
