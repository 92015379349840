import React from 'react';
import { Link } from 'react-router-dom';
import './LoadingModal.css';

const CheckFieldModal = ({show, message, handleFieldModal}) => {
  return (
    <div>
      { show &&
        <div className="modal-container">
          <div className="modal-body">
            <p>{ message }</p>
            <p>
              <Link onClick={() => handleFieldModal(false, '')} to="/contact-information">Siirry asiakastietoihin</Link>
            </p>
          </div>
        </div> 
      }
    </div>
  );
}

export default CheckFieldModal;