import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import BookStockContainer from './components/bookStock/BookStockContainer';
import Toast from './components/modals/Toast';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Toast />
      <Router>
        <BookStockContainer />
      </Router>
    </div>
  );
};

export default App;
