import React from "react";
import "./InputForm.css";
import { faUserCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckRemarks from "./CheckRemarks";
import TextField from "@material-ui/core/TextField";
import DropdownSelector from "../dropdownSelector/DropdownSelector";
import TabBar from "./TabBar";
import { countryList } from "../../utils/countryList";
import { insertIf } from "../../utils/helpers";
import {
  companyContactSchema,
  userContactSchema,
  validateSchema,
} from "../../utils/formValidator";
import { IconButton, Tooltip } from "@material-ui/core";

const InputForm = (props) => {
  const {
    appState: {
      user: { storages },
      user,
    },
    setUserData,
    setError,
    handleInputChange,
    removeUserCredentials,
  } = props;

  const rental = storages.reduce((accu, storage) => accu + storage.rental, 0);

  const handleValidation = (event) => {
    const {
      target: { name, validity },
    } = event;
    setError({ [name]: validity.valid });
  };

  const isCompany = user.customerType === "COMPANY";
  const { errors } = validateSchema(
    user,
    isCompany ? companyContactSchema : userContactSchema
  );

  return (
    <div className="input-form">
      <h2>
        <FontAwesomeIcon icon={faUserCircle} />
        Asiakkaan yhteystieot
      </h2>

      <TabBar
        user={user}
        setUserData={setUserData}
        removeUserCredentials={removeUserCredentials}
      />

      <div className="input-form-body">
        {user.customerType !== "COMPANY" && (
          <TextField
            error={errors.name}
            fullWidth
            margin="dense"
            variant="outlined"
            label="Nimi (etunimi sukunimi)"
            type="text"
            name="name"
            value={user.name || ""}
            placeholder={"Nimi (etunimi sukunimi)"}
            onChange={handleInputChange}
            onBlur={handleValidation}
            required
          />
        )}

        {user.customerType === "COMPANY" && (
          <TextField
            error={errors.company}
            margin="dense"
            label="Yrityksen nimi"
            fullWidth
            variant="outlined"
            type="text"
            name="company"
            value={user.company || ""}
            placeholder={"Yrityksen nimi"}
            onChange={handleInputChange}
            onBlur={handleValidation}
            required
          />
        )}

        {user.customerType === "COMPANY" && (
          <div className="form-input-icon-container">
            <TextField
              error={errors.contact}
              margin="dense"
              label="Yhteyshenkilö (nimenkirjoitusoikeutettu)"
              fullWidth
              variant="outlined"
              type="text"
              name="contact"
              value={user.contact || ""}
              placeholder="Yhteyshenkilö (nimenkirjoitusoikeutettu)"
              onChange={handleInputChange}
              onBlur={handleValidation}
              required
            />
          </div>
        )}

        <div className="form-input-icon-container">
          <TextField
            error={errors.ssn}
            margin="dense"
            label={
              user.customerType === "COMPANY"
                ? "Yhteyshenkilön henkilötunnus"
                : "Henkilötunnus"
            }
            fullWidth
            variant="outlined"
            type="text"
            name="ssn"
            value={user.ssn || ""}
            placeholder={
              user.customerType === "COMPANY"
                ? "Yhteyshenkilön henkilötunnus"
                : "Henkilötunnus"
            }
            onChange={handleInputChange}
            onBlur={handleValidation}
            required
          />
          {user.customerType === "COMPANY" && (
            <div className="form-input-icon-absolute">
              <Tooltip
                title="Yrityksen nimenkirjoitusoikeutetun henkilötunnus tarvitaan
                  ensisijaisesti vain allekirjoitusta varten"
                arrow
              >
                <IconButton style={{ marginLeft: 6, padding: 0, fontSize: 18 }}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>

        {user.customerType === "COMPANY" && (
          <TextField
            error={errors.businessId}
            margin="dense"
            label="Y-tunnus"
            fullWidth
            variant="outlined"
            type="text"
            name="businessId"
            value={user.businessId || ""}
            placeholder="Y-tunnus"
            onChange={handleInputChange}
            onBlur={handleValidation}
            required
          />
        )}

        <TextField
          error={errors.email}
          margin="dense"
          label="Sähköposti"
          fullWidth
          variant="outlined"
          type="text"
          name="email"
          value={user.email || ""}
          placeholder="Sähköposti"
          onChange={handleInputChange}
          onBlur={handleValidation}
          required
        />

        <TextField
          error={errors.phoneNumber}
          margin="dense"
          label="Puhelinnumero"
          fullWidth
          variant="outlined"
          type="text"
          name="phoneNumber"
          value={user.phoneNumber || ""}
          placeholder="Puhelinnumero"
          onChange={handleInputChange}
          onBlur={handleValidation}
          required
        />

        <TextField
          error={errors.address}
          margin="dense"
          label="Osoite"
          fullWidth
          variant="outlined"
          type="text"
          name="address"
          value={user.address || ""}
          placeholder="Osoite"
          onChange={handleInputChange}
          onBlur={handleValidation}
          required
        />

        <TextField
          error={errors.postCode}
          margin="dense"
          label="Postinumero"
          fullWidth
          variant="outlined"
          type="text"
          name="postCode"
          value={user.postCode || ""}
          placeholder="Postinumero"
          onChange={handleInputChange}
          onBlur={handleValidation}
          required
        />

        <TextField
          error={errors.city}
          margin="dense"
          label="Postitoimipaikka"
          fullWidth
          variant="outlined"
          type="text"
          name="city"
          value={user.city || ""}
          placeholder="Postitoimipaikka"
          onChange={handleInputChange}
          onBlur={handleValidation}
          required
        />

        <DropdownSelector
          margin="dense"
          name="country"
          defaultValue={user.country}
          title={"Valitse valtio"}
          itemList={countryList.sort()}
          handledropdownChange={handleInputChange}
        />

        <DropdownSelector
          margin="dense"
          name="billing"
          defaultValue={user.billing}
          title={"Valitse laskutustapa"}
          keyList={[
            "Sähköpostilasku",
            ...insertIf(user.customerType !== "COMPANY", "E-lasku"),
            ...insertIf(user.customerType === "COMPANY", "Verkkolasku"),
            "Paperilasku (+5 € laskutuslisä per lasku)",
          ]}
          itemList={[
            "Sähköpostilasku",
            ...insertIf(user.customerType !== "COMPANY", "E-lasku"),
            ...insertIf(user.customerType === "COMPANY", "Verkkolasku"),
            "Paperilasku",
          ]}
          handledropdownChange={handleInputChange}
        />

        {user.billing === "Verkkolasku" && (
          <i className="info-label">
            <div>
              Toimita verkkolaskutustiedot meille sähköpostitse&nbsp;
              <a href="mailto:info@pienvarastokeskus.fi?subject=Verkkolaskutustiedot">
                info@pienvarastokeskus.fi
              </a>
            </div>
          </i>
        )}

        <CheckRemarks
          handleRemarksBtnChange={props.handleRemarksBtnChange}
          removeUserData={props.removeUserData}
          rental={rental}
          user={user}
        />
      </div>
    </div>
  );
};

export default InputForm;
