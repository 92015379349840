import { 
  SET_USER_DATA_ROW,
  SET_MULTIPLE_ROWS_USER_DATA,
  SET_UTIL,
  SET_ERROR,
  REMOVE_ERRORS,
  SET_STORAGE_UTILS,
  DELETE_STORAGE,
  REMOVE_USER_DATA_ROW,
  REMOVE_USER_CREDENTIALS
} from '../actions/actionTypes';

import { removeFromObject } from '../utils/helpers';

const initialState = {
  user: {
    customerType: 'PERSON',
    storageSizeFilter: 'Kaikki',
    country: 'Suomi',
    storages: []
  },
  utils: {},
  errors: {},
};

export const appState = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA_ROW:
      return { 
        ...state,
        user: {
          ...state.user,
            [action.name]: action.value
        }
      };

    case REMOVE_USER_DATA_ROW:
      return {
        ...state,
        user: removeFromObject(state.user, action.key)
      };

    case REMOVE_USER_CREDENTIALS: 
      return {
        ...state,
        user: {
          customerType: state.user.customerType,
          storages: state.user.storages,
          startTime: state.user.startTime,
          storageSizeFilter: state.user.storageSizeFilter,
          storageLocation: state.user.storageLocation
        }
      };

    case SET_MULTIPLE_ROWS_USER_DATA:
      return { 
        ...state,
        user: {
          ...state.user,
            ...action.rows
        }
      };

    case SET_UTIL:
      return { 
        ...state,
        utils: {
          ...state.utils,
          [action.util]: action.value
        }
      };

    case SET_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.error
        }
      };

    case REMOVE_ERRORS:
      return {
        ...state,
        errors: {}
      };

    case SET_STORAGE_UTILS:
      return {
        ...state,
        user: {
          ...state.user,
          storages: state.user.storages.some(storage => storage.id === action.id) ?
            state.user.storages.map((storage) => {
              return storage.id === action.id ? {...storage, ...{ [action.name]: action.value }} : storage
            }) : 
            [ ...state.user.storages, { id: action.id, [action.name]: action.value }]
        }
      };
    case DELETE_STORAGE:
      return {
        ...state,
        user: {
          ...state.user,
          storages: state.user.storages.filter(storage => storage.id !== action.id)
            .map((storage, index) => ({ ...storage, ...{ id: index }} ))
        }
      };

    default:
      return state;
  }
};