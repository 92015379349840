import React, { useEffect } from 'react';
import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Agreement.css';

const Agreement = (props) => {
  const { getHtmlAgreement, appState: { utils } } = props;

  useEffect(() => {
    getHtmlAgreement();
  }, []);

  return (
    <div className="agreement-body">
      <h2>
        <FontAwesomeIcon 
          icon={faFileContract} 
        />
        Vuokrasopimuksen esikatselu
      </h2>
      <div 
        className="agreement-content" 
        dangerouslySetInnerHTML={{__html: utils.html}}
      />
    </div> 
  );
}

export default Agreement;
