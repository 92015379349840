import React, { useEffect, useMemo, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { fi } from 'date-fns/locale';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const dateTimeStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: 9,
      paddingTop: 10,
      paddingBottom: 20,
      border: '1px solid #c4c4c4',
      borderRadius: 5,
      color: '#646464',
    }
  })
);

const StaticDayPicker = (props) => {
  const { user } = props;
  const [openPicker, setOpenPicker] = useState(false);
  const classes = dateTimeStyles();
  const datePickerTheme = useMemo(() => {
    return createTheme({
      overrides: {
        MuiPickersDay: {
          daySelected: {
            backgroundColor: props.selectedDay ? '#f17940' : '#fff',
            color: props.selectedDay ? '#fff' : '#f17940',
            '&:hover': {
              backgroundColor: '#f17940',
              color: '#fff'
            },
          },
          current: {
            color: '#f17940',
            border: '1px solid #f17940'
          }
        }
      }
    })
  }, [props.selectedDay],
  );

  useEffect(() => {
    const maxDate = getLatestStorageAvailableDate();
    if (new Date(user.startTime) < maxDate) {
      props.handleDayClick(maxDate);
    }
  }, [user.startTime, user.storages])

  const disableWeekends = (date) => {
    const maxDate = getLatestStorageAvailableDate();
    maxDate.setDate(maxDate.getDate() - 1);
    return maxDate > date;
  }

  const getLatestStorageAvailableDate = () => {
    const dates = user.storages.filter(storage => storage.endDate).map(storage => storage.endDate);
    const maxTime = dates.length && dates.reduce((a, b) =>  a > b ? a : b);
    return new Date(maxTime);
  };

  const theme = useTheme();
  const largerThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  return largerThanSm ? (
    <fieldset className={classes.container}>
      <legend>Valitse aloituspäivä</legend>
      <Grid container justifyContent="center">
        <MuiPickersUtilsProvider locale={fi} utils={DateFnsUtils}>
          <ThemeProvider theme={datePickerTheme}>
            <KeyboardDatePicker
              label="asdasdas"
              variant="static"
              openTo="date"
              disableToolbar
              disablePast
              shouldDisableDate={(date) => disableWeekends(date)}
              value={new Date(props.selectedDay)}
              onChange={props.handleDayClick}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Grid>
    </fieldset>
  ) : (
    <MuiPickersUtilsProvider locale={fi} utils={DateFnsUtils}>
      <ThemeProvider theme={datePickerTheme}>
        <KeyboardDatePicker
          disableToolbar
          margin="normal"
          emptyLabel="Valitse aloituspäivä"
          fullWidth
          invalidDateMessage="Päivämäärä ei ole oikeassa muodossa"
          autoOk
          inputVariant="outlined"
          variant="inline"
          format="dd.MM.yyyy"
          label="Valitse aloituspäivä"
          open={openPicker}
          onClick={() => setOpenPicker(true)}
          onClose={() => setOpenPicker(false)}
          disablePast
          shouldDisableDate={(date) => disableWeekends(date)}
          InputAdornmentProps={{ position: "end" }}
          value={props.selectedDay ? new Date(props.selectedDay) : null}
          onChange={props.handleDayClick}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default StaticDayPicker;
