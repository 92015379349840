import { remarksModelTranslator } from '../utils/helpers';

const createAgreementQueryObject = (model) => {
  return {
    id: [model.selectedStorage.id],
    userId: [model.userId],
    startTime: model.startTime
  }
}

const createRemarksAndSignDocumentQueryObject = (model) => {
  return {
    startTime: model.startTime,
    name: model.name || model.company,
    ssn: model.ssn,
    phoneNumber: model.phoneNumber,
    email: model.email,
    address: model.address,
    postCode: model.postCode,
    city: model.city,
    country: model.country,
    customerType: model.customerType,
    company: model.company,
    contact: model.contact,
    businessId: model.businessId,
    checkCreditInformation: model.checkCreditInformation,
    payExtraRent: model.payExtraRent,
    rentGuarantee: 0,
    returnUrl: model.returnUrl,
    authMethod: model.authMethod,
    storages: model.storages,
    rental: model.rental,
    billing: model.billing,
    additionalName: model.name || model.company,
    additionalRentGuarantee: 0,
    additionalInfo: model.payExtraRent ? true : false,
    stockNames: model.stockNames,
  }
}

const createTenantAndAgreementQueryObject = (model) => {
  return {
    storageId: model.storageId,
    startTime: model.startTime,
    name: model.name,
    ssn: model.ssn,
    remarks: model.remarks,
    creditInformation: remarksModelTranslator(model.remarks),
    phoneNumber: model.phoneNumber,
    email: model.email,
    address: model.address,
    postCode: model.postCode,
    city: model.city,
    rental: model.rental,
    customerType: model.customerType,
    company: model.company,
    contact: model.contact,
    businessId: model.businessId,
    checkCreditInformation: model.checkCreditInformation,
    payExtraRent: model.payExtraRent,
    rentGuarantee: model.creditInformation ? model.rental * 3 : 0,
    country: model.country,
    billing: model.billing,
    returnUrl: model.returnUrl,
    authMethod: model.authMethod,
    agreementState: model.agreementState,
    invitationUuid: model.invitationUuid,
    documentUuid: model.documentUuid
  }
}

const createHtmlQueryObject = (model) => {
  return {
    startTime: model.startTime,
    name: model.name,
    additionalName: model.name || model.company,
    ssn: model.ssn,
    phoneNumber: model.phoneNumber,
    email: model.email,
    address: model.address,
    postCode: model.postCode,
    city: model.city,
    customerType: model.customerType,
    company: model.company,
    country: model.country,
    billing: model.billing,
    contact: model.contact,
    businessId: model.businessId,
    checkCreditInformation: model.checkCreditInformation,
    rentGuarantee: model.payExtraRent ? model.rental * 3 : 0,
    additionalRentGuarantee: model.payExtraRent ? model.rental * 3 : 0,
    stockNames: model.stockNames,
    storages: model.storages,
    rental: model.rental,
    additionalInfo: model.payExtraRent ? true : false
  }
}

export { 
  createAgreementQueryObject,
  createRemarksAndSignDocumentQueryObject,
  createTenantAndAgreementQueryObject,
  createHtmlQueryObject
};