export const SET_USER_DATA_ROW = 'SET_USER_DATA';
export const SET_MULTIPLE_ROWS_USER_DATA = 'SET_MULTIPLE_ROWS_USER_DATA';
export const SET_SELECTED_STORAGE = 'SET_SELECTED_STORAGE';
export const SET_UTIL = 'SET_UTIL';
export const SET_ERROR = 'SET_ERROR';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const SET_STORAGE_UTILS = 'SET_STORAGE_UTILS';
export const DELETE_STORAGE = 'DELETE_STORAGE';
export const REMOVE_USER_DATA_ROW = 'REMOVE_USER_DATA_ROW';
export const REMOVE_USER_CREDENTIALS = 'REMOVE_USER_CREDENTIALS';
