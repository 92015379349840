import React from "react";
import { useLocation } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const tabStyles = makeStyles(() =>
  createStyles({
    active: {
      fill: "#f26522",
    },
    stepLabel: {
      fontFamily: "BarlowCondensed",
      fontSize: 16,
    },
  })
);

const TabHeader = () => {
  const { pathname } = useLocation();
  const classes = tabStyles();
  const paths = [
    ["/", "Valitse pienvarasto"],
    ["/contact-information", "Asiakkaan tiedot"],
    ["/agreement", "Lue vuokrasopimus"],
    ["/sign", "Allekirjoitus"],
    ["/overview", "Yhteenveto"],
  ];

  const activeIndex = paths.findIndex((val) => val[0] === pathname);
  const theme = useTheme();
  const largerThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stepper activeStep={activeIndex} alternativeLabel>
      {paths.map((label) => (
        <Step key={label[1]}>
          <StepLabel
            classes={{ label: classes.stepLabel }}
            StepIconProps={{
              classes: {
                active: classes.active,
                completed: classes.active,
              },
            }}
          >
            {largerThanSm ? label[1] : ""}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default TabHeader;
