import { connect } from 'react-redux';
import { 
  setUserData,
  setMultipleRowsUserData,
  setUtils,
  setError,
  removeErrors,
  setStorageUtils,
  removeStorage,
  removeUserData,
  removeUserCredentials
} from '../../actions/appState';

import BookStock from './BookStock';

const mapStateToProps = (state) => {
  return {
    appState: state.appState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (name, value) => {
      dispatch(setUserData(name, value));
    },
    setMultipleRowsUserData: (rows) => {
      dispatch(setMultipleRowsUserData(rows));
    },
    setUtils: (util, value) => {
      dispatch(setUtils(util, value));
    },
    setError: (error) => {
      dispatch(setError(error));
    },
    removeErrors: () => {
      dispatch(removeErrors());
    },
    setStorageUtils: (name, value, id) => {
      dispatch(setStorageUtils(name, value, id))
    },
    removeStorage: (id) => {
      dispatch(removeStorage(id));
    },
    removeUserData: (key) => {
      dispatch(removeUserData(key));
    },
    removeUserCredentials: () => {
      dispatch(removeUserCredentials());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookStock);
