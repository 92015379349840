import React, { useEffect, useState } from 'react';
import { faCheckCircle, faArrowCircleRight, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import localization from '../../localization/localization';
import qs from 'query-string';
import './Overview.css';

const Overview = () => {
  const path = qs.parse(location.search);
  const signed = Boolean(path.state === 'signed');
  const remarks = path.remarks; //TODO Muutetaan arvot seuraaviksi: YES | NO | OLK
  const rental = path.rental;
  
  const [ strings, setStrings ] = useState({
    header: '',
    title: '',
    firstParagraph: '',
    secondParagraph: ''
  })

  const goFrontPage = () => {
    location = 'https://www.pienvarastokeskus.fi';
  };

  const getOverviewTexts = (state, remark) => {
    console.log(remark)
    if (state) {
      const texts = {
        'YES': {
          header: localization.overview.header.signed,
          title: localization.overview.title.remarks,
          firstParagraph: localization.overview.firstParagraph.remarks,
          secondParagraph: localization.overview.secondParagraph.remarks,
        },
        'NO': {
          header: localization.overview.header.signed,
          title: localization.overview.title.signed,
          firstParagraph: localization.overview.firstParagraph.signed
        },
        'OLK': {
          header: localization.overview.header.signed,
          title: localization.overview.title.olk,
          firstParagraph: localization.overview.firstParagraph.olk,
          secondParagraph: localization.overview.secondParagraph.olk,
        }
      };
      return texts[remark];
    } else {
      return {
        header: localization.overview.header.error,
        title: localization.overview.title.error,
        firstParagraph: localization.overview.firstParagraph.error
      }
    }
  }



  useEffect(() => {
    sendConversion();
    const strings = getOverviewTexts(signed, remarks)
    console.log(strings)
    setStrings({ ...strings });
  }, [])

  const sendConversion = () => {
    if (signed) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-833162709/h-9FCOPL1KoDENWbpI0D', 
        value: rental, 
        currency: 'EUR', 
        transaction_id: ''
      });
    }
  }

  return (
    <div className="overview-container">
      <h2>
        <FontAwesomeIcon 
          icon={
            signed ? 
              remarks ? 
                faExclamationCircle : 
                faCheckCircle : 
              faExclamationCircle
          }
        />
          {strings.header}
      </h2>
      <div className="overview-body">
        <p className="overview-check-icon">
          <FontAwesomeIcon 
            icon={
              signed ? 
                remarks ? 
                  faExclamationCircle : 
                  faCheckCircle : 
                faExclamationCircle
            } 
          />
        </p>
        <h1>
          {strings.title}
        </h1>
        <p className="overview-first-paragraph">
          {strings.firstParagraph}
        </p>
        {strings.secondParagraph && 
          <p className="overview-second-paragraph">
            {strings.secondParagraph} 
            {remarks && 
              <a href="mailto:info@pienvarastokeskus.fi"> info@pienvarastokeskus.fi</a>
            }
          </p>
        }
        <button 
          className="overview-go-frontpage" 
          onClick={goFrontPage}
        >
          {localization.overview.submit}
          <FontAwesomeIcon 
            icon={faArrowCircleRight} 
          />
        </button>
        <hr/>
        <img 
          src={process.env.PUBLIC_URL + '/Pienvarastokeskus-logo_RGB(Orange).png'} 
          alt={'pienvarastokeskus-logo'}
        />
      </div>
    </div>
  );
};

export default Overview;