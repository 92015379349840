import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const DropdownSelector = ({ name, title, handledropdownChange, itemList, defaultValue, margin, keyList }) => {
  const optionRows = itemList && itemList.map((item, index) => 
    <MenuItem key={index} value={item}>{keyList ? keyList[index] : item}</MenuItem>
  );
  return (
    <TextField
      label={title}
      variant="outlined"
      margin={margin ? margin : "normal"}
      fullWidth
      name={name}
      select
      SelectProps={{
        value: defaultValue || '',
        onChange: handledropdownChange,
      }}
    >
    {optionRows ? 
      optionRows : 
      <MenuItem></MenuItem>
    }
    </TextField>
  );
};

export default DropdownSelector;