import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './InputForm.css';

const TabBar = ({user, removeUserCredentials, setUserData}) => {
  return (
    <div className="input-form-header">
      <AppBar 
        position="static" 
        color="transparent"
        elevation={0}
      >
        <Tabs 
          value={user.customerType}
          onChange={(e, value) => {
            removeUserCredentials();
            setUserData('customerType', value);
          }}
          TabIndicatorProps={{
            style: { 
              background: '#f17940' 
            }
          }}
          variant="fullWidth"
        >
          <Tab 
            icon={
              <FontAwesomeIcon 
                style={{ 
                  fontSize: 20,
                  color: 'gray' 
                }} 
                icon={faUser} 
              />
            }
            value="PERSON" 
            label={<span className="tab-bar">Yksityishenkilö</span>}
          />
          <Tab 
            icon={
              <FontAwesomeIcon 
                style={{ 
                  fontSize: 20,
                  color: 'gray' 
                }} 
                icon={faUsers} 
              />
            } 
            value="COMPANY"
            label={<span className="tab-bar">Yritys</span>}
          />
        </Tabs>
      </AppBar>
    </div>
  );
};

export default TabBar;