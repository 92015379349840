import React from 'react';
import './LoadingModal.css';
import Loader from '../bookStock/Loader';

const LoadingModal = ({show}) => {
  return (
    <div>
      { show &&
        <div className="modal-container">
          <div className="modal-body">
          <Loader />
          </div>
        </div> 
      }
    </div>
  );
}

export default LoadingModal;