import axios from 'axios';

export default class Api {

  // query = () => {
  //   let instance = axios.create(defaultOptions);
  //   instance.interceptors.request.use(() => {
  //     const token = "localStorage.getItem('token');"
  //     config.headers.Authorization =  token ? `Bearer ${token}` : '';
  //     return config;
  //   });
  //   return axios;
  // }
  
  /**
   * Create or update row to Varaukset table.
   * @param {*} data 
   */
  async createOrUpdateOrder(data) {
    axios.defaults.withCredentials = true;
    return axios.post('/api/v1.0/storage/create-order', {
      ...data
    }, {
      withCredentials: true
    }).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      console.error(error);
      return Promise.reject(error);
    })
  };

  /**
   * Create new tenant and agreement
   * @param {*} data 
   */
  async createNewTenantAndAgreement(data) {
    return axios.post('/api/v1.0/storage/create-tenant-and-agreement', {
      ...data
    }, {
      withCredentials: true
    }).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      console.error(error);
      return Promise.reject(error);
    })
  };

  /**
   * Get storages within location and size
   * @param {*} location 
   */
  async getAvailableStorageswithinLocation(location) {
    return axios.get('/api/v1.0/storage/get-storages?', {
      withCredentials: true,
      params: {
        location: location
      }
    })
    .then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      console.error(error);
      return Promise.reject(error);
    });
  };

  /**
   * Get all storage center locations
   */
  async getAllStorageLocations() {
    return axios.get('/api/v1.0/storage/get-storage-locations', {
      withCredentials: true
    }).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      console.error(error);
      return Promise.reject(error);
    });
  };

  /**
   * 
   * @param {Object} data 
   */
  async checkRemarksAndSignDocument(data) {
    return axios.post('/api/v1.0/agreement/check-remarks-sign-document', {
      ...data
    }, {
      withCredentials: true
    }).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      console.error(error);
      return Promise.reject(error);
    });
  };

  /**
   * Gives Visma Sign authentication methods, ex. tupas-nordea...
   */
  async getVismaSignAuthMethods() {
    return axios.get('/api/v1.0/agreement/auth-methods', {
      withCredentials: true
    }).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      console.error(error);
      return Promise.reject(error);
    });
  }

  /**
   * Gives html string
   */
  async getGeneratedHtml(data) {
    return axios.post('/api/v1.0/agreement/html-agreement', {
      ...data
    }, {
      withCredentials: true
    }).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      console.error(error);
      return Promise.reject(error);
    });
  }
};