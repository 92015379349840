import React, { useEffect } from "react";
import { Route, Link, useHistory, useLocation } from "react-router-dom";
import {
  faArrowAltCircleRight,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import localization from "../../localization/localization";
import Button from "@material-ui/core/Button";
import "./TabHeader.css";

const RouterTab = (props) => {
  const { valid, children, path, next, previous } = props;

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    pathname !== "/overview" && history.push("/");
  }, []);

  return (
    <div className="tab-container">
      <Route exact path={path}>
        {children}
        {!!previous && (
          <Link to={previous ? previous : "/"}>
            <Button
              className="tab-btn previous-btn"
              startIcon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
            >
              {localization.router.previous}
            </Button>
          </Link>
        )}

        {!!next && (
          <Link to={next ? next : "/"}>
            <Button
              variant="outlined"
              className="tab-btn next-btn"
              disabled={!valid}
              endIcon={<FontAwesomeIcon icon={faArrowAltCircleRight} />}
            >
              {localization.router.next}
            </Button>
          </Link>
        )}
      </Route>
    </div>
  );
};

export default RouterTab;
