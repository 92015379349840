
const dateFormatter = (rawDate, format) => {
  const date = new Date(rawDate);
  const time = {
    minute: date.getMinutes(),
    hour: date.getHours(),
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear()
  };
  switch (format) {
    case "MM/DD/YYYY":
      return `${time.month}/${time.day}/${time.year}`;
    case "DD/MM/YYYY hh:mm":
      return `${time.day}/${time.month}/${time.year} ${time.hour}:${time.minute}`;
    case "DD/MM/YYYY":
      return `${time.day}/${time.month}/${time.year}`;
    case "DD.MM.YYYY":
      return `${time.day}.${time.month}.${time.year}`;
    default: 
      return date;
  }
};

const insertIf = (condition, ...value) => {
  return condition ? value : [];
};

const removeFromObject = (object, key) => {
  const { [key]: remove, ...rest } = object;
  return rest;
};

const errorMessageTranslator = (key) => {
  switch (key) {
    case 'Company not found!':
    case 'Invalid Id': 
      return 'Y-tunnus on virheellinen';
    case 'Id uri segment must be either valid social security number or valid BisnodeId.':
    case 'Invalid identifier':
      return 'Henkilötunnus on virheellinen';
    case 'Requirements invalid':
      return 'Käyttäjän tiedot on virheelliset';
    default: 
      return 'Jokin meni vikaan...';
  }
};

const remarksModelTranslator = (remark) => {
  if (!remark) return 'OK';
  else if (remark === true) return '3kk vakuus pyydetty';
  else if (remark === 'OLK') return 'Omaehtoinen luottokielto';
};

const generateToken = (str) => {
  const dateStr = new Date().setHours(0,0,0,0).toString().substr(0, 4);
  const hash = str ?
    JSON.stringify(str).concat(dateStr) :
    'eo05usmwogfbnFaLxmyz'.concat(dateStr);
  return Buffer.from(hash).toString('base64').substr(0, 24);
};

export { 
  dateFormatter, 
  insertIf, 
  removeFromObject,
  errorMessageTranslator,
  remarksModelTranslator,
  generateToken
};
