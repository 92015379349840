import React, { Fragment } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const useToolbarStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      marginTop: 15,
      borderRadius: 5,
      marginBottom: 16,
      border: '1px solid #c4c4c4',
      backgroundColor: '#dff0d8',
      minHeight: 55
    },
    title: {
      flex: '1 1 100%',
      fontWeight: 500,
      color: '#646464'
    },
  }),
);

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { storages, removeStorages } = props;
  const numSelected = storages.length;
  return numSelected ? (
    <Toolbar
      className={classes.root}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} component="div">
          {numSelected > 1 ? `${numSelected} varastoa valittu` : `${numSelected} varasto valittu`} 
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div"></Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Poista">
          <IconButton onClick={removeStorages}>
            <FontAwesomeIcon size='xs' icon={faTrash} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  ) : <Fragment />;
};

export default TableToolbar;