import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { dateFormatter } from "../../utils/helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const headerStyles = makeStyles(() =>
  createStyles({
    header: {
      backgroundColor: "#eeeeee",
      fontWeight: 500,
      color: "#646464",
      fontFamily: "BarlowCondensed",
      fontSize: 17,
    },
  })
);

const TableHeader = () => {
  const classes = headerStyles();
  const headCells = [
    { align: "left", label: "Koko", colSpan: 2 },
    { align: "right", label: "Hinta", colSpan: 1 },
    { align: "right", label: "Vapautuu", colSpan: 1 },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.header}
            key={headCell.label}
            align={headCell.align}
            colSpan={headCell.colSpan}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    tableContainer: {
      marginTop: 15,
      maxHeight: 265,
    },
    tableRow: {
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: "#dff0d8",
      },
    },
    linearBody: {
      marginTop: 15,
      borderRadius: 5,
      textAlign: "center",
      fontSize: 17,
      color: "#575757",
      fontWeight: 800,
      paddingLeft: 5,
      paddingTop: 12,
      paddingBottom: 12,
      backgroundImage: "linear-gradient(#eeeeee, #ffffff)",
      height: 170,
    },
    image: {
      height: 65,
      width: 65,
      borderRadius: "50%",
      backgroundColor: "#ffffff",
      padding: 5,
      marginTop: 20,
      boxShadow: "0 4px 6px -2px #757575",
    },
    loaderBody: {
      position: "absolute",
      top: "50%",
      left: "50%",
      zIndex: 100,
      transform: "translate(-50%, -50%)",
    },
  })
);

const StorageTable = (props) => {
  const {
    awailableStorages,
    handleStorageTable,
    loading,
    user: { storageLocation, storages, storageSizeFilter },
  } = props;
  const classes = useStyles();

  const handleClick = (row) => {
    const newList = storages.some((storage) => storage.id === row.id)
      ? storages.filter((storage) => storage.id !== row.id)
      : storages.concat(row);
    handleStorageTable(newList);
  };

  const getSizeByKey = (key) => {
    const size = {
      "1 - 5 m2": [1, 5],
      "5 - 10 m2": [5, 10],
      "10 - 30 m2": [10, 30],
      Kaikki: [0, 100],
    };
    return size[key];
  };

  const size = storageSizeFilter && getSizeByKey(storageSizeFilter);
  const storageList = storageSizeFilter
    ? awailableStorages.filter(
        (x) => x.sizeM2 >= size[0] && x.sizeM2 <= size[1]
      )
    : awailableStorages;
  const isSelected = (id) => storages.some((storage) => storage.id === id);

  return (
    <div className={classes.root}>
      {loading && <LinearProgress />}
      {storageList.length ? (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHeader />
            <TableBody>
              {storageList.map((row) => (
                <TableRow
                  hover
                  style={{
                    cursor: "pointer",
                  }}
                  className={classes.tableRow}
                  onClick={() => handleClick(row)}
                  role="checkbox"
                  aria-checked={isSelected(row.id)}
                  tabIndex={-1}
                  key={row.id}
                  selected={isSelected(row.id)}
                  options={{}}
                >
                  <TableCell padding="checkbox">
                    <Checkbox color="default" checked={isSelected(row.id)} />
                  </TableCell>
                  <TableCell padding="none">
                    {row.sizeM2} m<sup>2</sup>
                  </TableCell>
                  <TableCell align="right">{row.rental} €/kk</TableCell>
                  {row.state === "vapaa" ? (
                    <TableCell align="right">
                      <FontAwesomeIcon
                        style={{ marginRight: 5 }}
                        color="#82c91e"
                        icon={faCheckCircle}
                      />
                      {row.state}
                    </TableCell>
                  ) : (
                    <TableCell align="right">
                      <FontAwesomeIcon
                        style={{ marginRight: 5 }}
                        color="#ff9800"
                        icon={faInfoCircle}
                      />
                      {dateFormatter(row.endDate, "DD.MM.YYYY")}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !storageLocation ? (
        <div className={classes.linearBody}>
          <img
            className={classes.image}
            alt="storage-icon-image"
            src={process.env.PUBLIC_URL + "pienvarastokeskus_logo_small.png"}
          />
          <p>Valitse Pienvarastokeskus!</p>
        </div>
      ) : (
        <div className={classes.linearBody}>
          <FontAwesomeIcon
            style={{ marginTop: 30 }}
            size="4x"
            color="#e2747e"
            icon={faTimesCircle}
          />
          <p>Ei vapaita varastoja</p>
        </div>
      )}
    </div>
  );
};

export default StorageTable;
