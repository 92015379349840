import React, { useEffect, useRef, useState } from 'react';
import DropdownSelector from '../dropdownSelector/DropdownSelector';
import StaticDayPicker from '../staticDayPicker/StaticDayPicker';
import StorageTable from '../storageTable/StorageTable';
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableToolbar from '../storageTable/TableToolbar'
import './SelectStorage.css';

const SelectStorage = (props) => {
  const {
    handleStorageTable,
    awailableStorages, 
    storageLocations,
    appState: { 
      user: { 
        storageLocation, 
        storageSizeFilter,
        startTime,
        storages
      } 
    } 
  } = props;
  const locations = storageLocations && storageLocations.map(loc => loc.storageCenter);

  const counterRef = useRef(null);
  const [countDown, setCountDown] = useState(30 * 60); // 30 minutes

  useEffect(() => {
    if (countDown > 0) {
      counterRef.current = setTimeout(() => setCountDown(countDown - 1), 1000);
    } else {
      // Aja täälä reduxin tyhjennys
    }
    return () => clearTimeout(counterRef.current)
  });

  const seconds = (countDown % 60).toString().padStart(2, '0');
  const minutes = Math.floor(countDown / 60).toString().padStart(2, '0');

  return (
    <div className="select-storage-body">
      <h2><FontAwesomeIcon icon={faBoxOpen} />Valitse pienvarasto
        {/* <span style={{float: 'right'}}>Istunto vanhenee {minutes}:{seconds}</span> */}
      </h2>
      <div className="flex-column">

        <DropdownSelector
          name={"storageLocation"}
          title={"Valitse Pienvarastokeskus"}
          disabled={false}
          itemList={locations}
          handledropdownChange={props.handleLocationDropdownChange}
          defaultValue={storageLocation}
        />

        <DropdownSelector
          name={"storageSizeFilter"}
          title={"Varaston koko"}
          disabled={!storageLocation}
          itemList={["1 - 5 m2", "5 - 10 m2", "10 - 30 m2", "Kaikki"]}
          handledropdownChange={props.handleDropdownChange}
          defaultValue={storageSizeFilter}
        />

        <StorageTable
          awailableStorages={awailableStorages}
          handleStorageTable={handleStorageTable}
          user={props.appState.user}
          loading={props.loadingStorages}
        />

      </div>
      <div className="flex-column">
      <TableToolbar
        storages={storages}
        removeStorages={() => {
          handleStorageTable([]);
        }}
      />
        <StaticDayPicker
          selectedDay={startTime} 
          handleDayClick={props.handleDayClick}
          removeUserCredentials={props.removeUserCredentials}
          user={props.appState.user}
        />
      </div>
    </div>
  );
};

export default SelectStorage;