import React, { useState, useEffect } from 'react';
import Api from '../../api/api';
import { 
  createTenantAndAgreementQueryObject, 
  createHtmlQueryObject,
  createRemarksAndSignDocumentQueryObject
 } from '../../api/model';
import InputForm from '../inputForm/InputForm';
import ErrorLabel from './ErrorLabel';
import LoadingModal from '../modals/LoadingModal';
import CheckFieldModal from '../modals/CheckFieldModal';
import TabHeader from '../tabHeader/TabHeader';
import RouterTab from '../tabHeader/RouterTab';
import Sign from '../sign/Sign';
import Agreement from '../agreement/Agreement';
import Overview from '../overview/Overview';
import SelectStorage from '../selectStorage/SelectStorage';
import AppHeader from '../appHeader/AppHeader';
import { 
  dateFormatter, 
  errorMessageTranslator 
} from '../../utils/helpers';
import { toast } from 'react-toastify';
import { 
  validateSchema,
  storageSchema,
  userContactSchema,
  companyContactSchema,
} from '../../utils/formValidator';
import './BookStock.css';

const api = new Api();

const BookStock = (props) => {

  const [state, setState] = useState({
    showError: false,
    awailableStorages: [],
    loadingStorages: false,
    loadingAgreement: false,
    showFieldModal: false,
    fieldModalMessage: null
  });

  const [activeStorage, setActiveStorage] = useState(0);

  useEffect(() => {
    api.getAllStorageLocations().then(response => {
      setState({
        ...state,
        storageLocations: response
      });
    })
  }, []);

  const handleDayClick = (day) => {
    props.setUserData('startTime', dateFormatter(day, 'MM/DD/YYYY'));
  };

  const handleInputChange = (event) => {
    const { target: { name, type, checked, value } } = event;
    props.setUserData(name, type === 'checkbox' ? checked : value === "" ? null : value);
  };

  const handleRemarksBtnChange = (name, value) => {
     props.setUserData(name, value);
  };

  const handleDropdownChange = (e) => {
    const { target: { name, value } } = e;
    props.setUserData(name, value);
  };

  const handleStorageChange = (storage) => {
    props.setStorageUtils(
      'selectedStorage',
      storage,
      activeStorage
    );
  };

  const handleStorageTable = (storages) => {
    props.setUserData('storages', storages);
    const storageIds = storages.map(storage => storage.id);
    api.createOrUpdateOrder({ storageId: storageIds });
  }

  const handleLocationDropdownChange = (e) => {
    const { target: { value } } = e;
    setState({ 
      ...state,
      loadingStorages: true 
    });
    handleDropdownChange(e);
    api.getAvailableStorageswithinLocation(value).then(response => {
      setState({
        ...state,
        awailableStorages: response,
        loadingStorages: false
      });
    });
  };

  const reserveStorageAndSignDocument = async (body) => {
    setState({ ...state, loadingAgreement: true });
    const { appState: { user: { storages, phoneNumber } } } = props;
    const rental = storages.reduce((accu, storage) => accu + storage.rental, 0);
    const storageIds = storages.map((storage) => storage.id);
    const stockNames = storages.map((storage) =>  storage.stock).join(', ');

    const siqnQuery = createRemarksAndSignDocumentQueryObject({ 
      ...body, 
      rental: rental,
      stockNames: stockNames
    });

    return api.checkRemarksAndSignDocument(siqnQuery).then(response => {
      const { remarks, invitation } = response;
      
      const agreementQuery = createTenantAndAgreementQueryObject({
        ...body,
        ...invitation,
        remarks,
        agreementState: 'odottaa',
        storageId: storageIds,
        phoneNumber: phoneNumber.indexOf('0') === 0 ? phoneNumber.replace( '0', '+358' ) : phoneNumber
      });

      return api.createNewTenantAndAgreement(agreementQuery).then(() => {
        setState({ ...state, loadingAgreement: false });
        return invitation;
      });

    }).catch((err) => {
      setState({ ...state, loadingAgreement: false });
      if (err.response?.data?.message) {
        toast.warn(errorMessageTranslator(err.response.data.message));
      } else {
        toast.warn(errorMessageTranslator());
      }
      return false;
    });
  };

  const handleFieldModal = (active, message) => {
    setState({ ...state,
      showFieldModal: active, 
      fieldModalMessage: message
    });
  };

  const getHtmlAgreement = async () => {
    const { appState: { user: { storages, phoneNumber } } } = props;
    const rental = storages.reduce((accu, storage) => accu + storage.rental, 0);
    const stockNames = storages.map((storage) =>  storage.stock).join(', ');
    const object = createHtmlQueryObject({
      ...appState.user,
      rental: rental,
      stockNames: stockNames,
      phoneNumber: phoneNumber.indexOf('0') === 0 ? phoneNumber.replace( '0', '+358' ) : phoneNumber
    });
    const html = await api.getGeneratedHtml(object);
    setUtils('html', html);
  };

  const { appState: { user } } = props;

  const { appState, setUtils, setMultipleRowsUserData, setError } = props;
  const { user: { customerType } } = appState;
  const isCompany = customerType === 'COMPANY';

  return (
    <div className="book-stock-component-body">
      <AppHeader />
      <TabHeader 
        setUtils={setUtils}
        appState={appState}
      />
      <div className="book-stock-tab-container">
        <RouterTab
          path={'/'}
          next={'/contact-information'}
          setUtils={setUtils}
          appState={appState}
          valid={validateSchema(user, storageSchema).valid}
          >
          <SelectStorage 
            handleLocationDropdownChange={handleLocationDropdownChange}
            handleDropdownChange={handleDropdownChange}
            handleInputChange={handleInputChange}
            handleStorageChange={handleStorageChange}
            handleDayClick={handleDayClick}
            setActiveStorage={setActiveStorage}
            handleStorageTable={handleStorageTable}
            removeUserCredentials={props.removeUserCredentials}
            setMultipleRowsUserData={setMultipleRowsUserData} // TODO: Poistetaan
            selectedStorage={props.appState.user.storages[activeStorage]}
            storageLocations={state.storageLocations}
            awailableStorages={state.awailableStorages}
            appState={appState}
            loadingStorages={state.loadingStorages}
            activeStorage={activeStorage}
          />
        </RouterTab>

        <RouterTab 
          path={'/contact-information'}
          next={'/agreement'}
          previous={'/'}
          setUtils={setUtils} 
          appState={appState}
          valid={
            isCompany ? 
              validateSchema(user, companyContactSchema).valid : 
              validateSchema(user, userContactSchema).valid
          }
          >
          <InputForm 
            handleInputChange={handleInputChange} 
            removeUserCredentials={props.removeUserCredentials}
            appState={appState}
            setUtils={setUtils}
            handleRemarksBtnChange={handleRemarksBtnChange}
            setError={setError}
            setUserData={props.setUserData}
            removeUserData={props.removeUserData}
          />
          {state.showError ? <ErrorLabel errorMessge={'Täytä kaikki kentät'}/> : null}
        </RouterTab>

        <RouterTab 
          path={'/agreement'}
          next={'/sign'}
          previous={'/contact-information'}
          setUtils={setUtils} 
          appState={appState}
          valid={true}>
            <Agreement appState={appState} getHtmlAgreement={getHtmlAgreement} />
        </RouterTab>

        <RouterTab
          path={'/sign'}
          previous={'/agreement'}
          valid={validateSchema(user, storageSchema).valid}
          appState={appState}>
          <Sign 
            getVismaSignAuthMethods={api.getVismaSignAuthMethods}
            reserveStorageAndSignDocument={reserveStorageAndSignDocument}
            setUserData={props.setUserData}
            appState={appState}
          />
        </RouterTab>

        <RouterTab
          path={'/overview'}
          setUtils={setUtils}
          appState={appState}>
            <Overview />
        </RouterTab>
      
      </div>
      <LoadingModal show={state.loadingAgreement} />
      <CheckFieldModal 
        message={state.fieldModalMessage} 
        show={state.showFieldModal}
        handleFieldModal={handleFieldModal}
      />
    </div>
  );
};

export default BookStock;