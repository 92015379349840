const fi = {
  overview: {
    header: {
      signed: 'Varaston varaus onnistui',
      error: 'Jokin meni pieleen'
    },
    title: {
      signed: 'Kiitos vuokrasopimuksen allekirjoittamisesta!',
      error: 'Hupsis!\nTaisit peruuttaa allekirjoituksen',
      remarks: 'Kiitos vuokrasopimuksen allekirjoittamisesta!\n mutta...',
      olk: 'Kiitos vuokrasopimuksen allekirjoittamisesta!\n mutta...'
    },
    firstParagraph: {
      signed: 'Löydät allekirjoitetun vuokrasopimuksen sähköpostistasi. Muista tarkastaa myös roskapostikansio.',
      error: 'Ei hätää voit edelleen allekirjoittaa sopimuksen 1 tunnin ajan.\nLöydät linkin allekirjoitukseen sähköpostista.',
      remarks: 'Sinulla vaikuttaa olevan ainakin yksi maksuhäiriömerkintä, minkä takia sinun tulee maksaa vuokravakuus ennen vuokrauksen aloittamista.',
      olk: 'asd'
    },
    secondParagraph: {
      signed: 'Ovikoodit tulevat sinulle vuokrasopimuksen alkamispäivänä viimeistään klo 05:00 sähköpostiisi. Mikäli vuokrasopimus on valittu alkavaksi heti, löydät ovikoodit jo nyt sähköpostistasi.',
      remarks: 'Ohjeet vakuuden maksuun löydät vuokrasopimuksesi Lisätietoja-kentästä. Toimita kuitti maksusta meille sähköpostiin',
      olk: 'asd'
    },
    submit: 'Palaa etusivulle'
  },
  checkRemarks: {
    tooltip: 'Tarkistamme kaikilta vuokralaisilta maksuhäiriömerkinnät ennen vuokrauksen aloittamista. \n \n Mikäli maksuhäiriömerkintöjä on, vaadimme minimivuokra-aikaa (yleensä 3 kk) vastaavan vuokravakuuden. \n \n Jos maksuhäiriömerkintöjä ei ole, vakuutta ei tarvitse maksaa.',
    buttonYes: 'Kyllä',
    buttonNo: 'En',
    noLabel: 'Valitettavasti emme voi vuokrata varastoa sinulle.',
    payExtraLabel: "Vuokravakuuden summa:",
    person: {
      firstTitle: "Annan luvan luottotietojeni tarkistamiseen",
      secondTitle: "Olen valmis maksamaan vuokravakuuden:",
      givePermissionSection: {
        buttonYesButRemarks: 'Kyllä, mutta minulla on maksuhäiriömerkintä'
      }
    },
    company: {
      firstTitle: "Annan luvan yrityksen luottotietojen tarkastukseen",
      secondTitle: "Maksamme vuokravakuuden:",
      givePermissionSection: {
        buttonYesButRemarks: 'Kyllä, mutta yrityksellä on maksuhäiriömerkintä'
      }
    }
  },
  router: {
    next: 'seuraava',
    previous: 'edellinen'
  }
};

export default fi;