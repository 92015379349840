import React from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import localization from "../../localization/localization";
import Button from "@material-ui/core/Button";
import "./CheckRemarks.css";
import { IconButton, Tooltip } from "@material-ui/core";

const CheckRemarks = ({
  user,
  handleRemarksBtnChange,
  rental,
  removeUserData,
}) => {
  const { customerType, checkCreditInformation, payExtraRent } = user;

  const handleBtnChange = (key, value) => {
    handleRemarksBtnChange(key, value);
    if (key === "checkCreditInformation" && value !== null) {
      removeUserData("payExtraRent");
    }
  };

  return (
    <div className="check-remarks-container">
      <p className="check-remarks-container-title">
        {customerType !== "COMPANY"
          ? localization.checkRemarks.person.firstTitle
          : localization.checkRemarks.company.firstTitle}
        <Tooltip title={localization.checkRemarks.tooltip} arrow>
          <IconButton style={{ marginLeft: 6, padding: 0, fontSize: 18 }}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </IconButton>
        </Tooltip>
      </p>
      <div className="check-remarks-btn-section">
        <Button
          className={checkCreditInformation ? "active" : ""}
          onClick={() => handleBtnChange("checkCreditInformation", true)}
        >
          {localization.checkRemarks.buttonYes}
        </Button>
        <Button
          className={checkCreditInformation === false ? "active" : ""}
          onClick={() => handleBtnChange("checkCreditInformation", false)}
        >
          {localization.checkRemarks.buttonNo}
        </Button>
        <Button
          className={checkCreditInformation === null ? "active" : ""}
          onClick={() => handleBtnChange("checkCreditInformation", null)}
        >
          {customerType !== "COMPANY"
            ? localization.checkRemarks.person.givePermissionSection
                .buttonYesButRemarks
            : localization.checkRemarks.company.givePermissionSection
                .buttonYesButRemarks}
        </Button>
      </div>

      {/* User has remarks */}
      {checkCreditInformation === null && "checkCreditInformation" in user && (
        <div className="check-remarks-second-section">
          <p className="check-remarks-container-title">
            {customerType !== "COMPANY"
              ? localization.checkRemarks.person.secondTitle
              : localization.checkRemarks.company.secondTitle}
          </p>
          <div className="check-remarks-btn-section">
            <Button
              className={payExtraRent && "payExtraRent" in user ? "active" : ""}
              onClick={() => handleBtnChange("payExtraRent", true)}
            >
              {localization.checkRemarks.buttonYes}
            </Button>
            <Button
              className={
                !payExtraRent && "payExtraRent" in user ? "active" : ""
              }
              onClick={() => handleBtnChange("payExtraRent", false)}
            >
              {localization.checkRemarks.buttonNo}
            </Button>
            {"payExtraRent" in user && (
              <p className="check-remarks-warn-label">
                {payExtraRent
                  ? localization.checkRemarks.payExtraLabel
                  : localization.checkRemarks.noLabel}
                {payExtraRent && <b>{" " + rental * 3} €</b>}
              </p>
            )}
          </div>
        </div>
      )}

      {/* User does not give permission to verify credit information */}
      {checkCreditInformation === false && "checkCreditInformation" in user && (
        <div className="check-remarks-second-section">
          <p className="check-remarks-warn-label">
            {localization.checkRemarks.noLabel}
          </p>
        </div>
      )}
    </div>
  );
};

export default CheckRemarks;
