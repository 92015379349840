
import Joi from 'joi';

export const storageSchema = Joi.object().keys({
  storages: Joi.array().items(
    Joi.object({
      id: Joi.string().required(),
      rental: Joi.number().required(),
      sizeM2: Joi.number().required(),
    }).required(),
  ),
  customerType: Joi.string().required(),
  startTime: Joi.string().required(),
}).options({ allowUnknown: true });

export const userContactSchema = Joi.object().keys({
  name: Joi.string().required(),
  email: Joi.string().email({ tlds: { allow: false } }),
  phoneNumber: Joi.string().strict().trim().min(5).max(17).required(),
  address: Joi.string().required(),
  city: Joi.string().required(),
  country: Joi.string().required(),
  postCode: Joi.number().required(),
  ssn: Joi.string().pattern(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d-|[01]\dA)\d{3}[\dA-Za-z]$/).required(),
  billing: Joi.string().required(),
  checkCreditInformation: Joi.boolean().invalid(false).allow(null).required(),
  payExtraRent: Joi.boolean().invalid(false).when('checkCreditInformation', {
    is: Joi.any().valid(null),
    then: Joi.required(),
    otherwise: Joi.optional()
  })
}).options({ allowUnknown: true });

export const companyContactSchema = Joi.object().keys({
  businessId: Joi.string().pattern(/^([0-9]{6,7})-([0-9])$/).required(),
  company: Joi.string().required(),
  contact: Joi.string().required(),
  email: Joi.string().email({ tlds: { allow: false } }),
  phoneNumber: Joi.string().strict().trim().min(5).max(17).required(),
  address: Joi.string().required(),
  city: Joi.string().required(),
  country: Joi.string().required(),
  postCode: Joi.number().required(),
  ssn: Joi.string().pattern(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d-|[01]\dA)\d{3}[\dABCDEFHJKLMNPRSTUVWXY]$/).required(),
  billing: Joi.string().required(),
  checkCreditInformation: Joi.boolean().invalid(false).allow(null).required(),
  payExtraRent: Joi.boolean().invalid(false).when('checkCreditInformation', {
    is: Joi.any().valid(null),
    then: Joi.required(),
    otherwise: Joi.optional()
  })
}).options({ allowUnknown: true });

export const validateSchema = (data, schema) => {
  const result = schema.validate(data, { abortEarly: false }); 
  const errors = (result?.error?.details || []).reduce((accu, err) => {
    return !!err.context.value
      ? { ...accu, [err.context.key]: !!err.context.value }
      : accu;
  }, {});

  return {
    valid: !result.error,
    errors: errors
  }
};