import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import './Sign.css';

const Sign = (props) => {
  const { 
    getVismaSignAuthMethods, 
    reserveStorageAndSignDocument, 
    setUserData, 
    appState: { user }
  } = props;
  const [authMethods, setAuthMethods] = useState([]);

  const fetchAuthMethods = async () => {
    const res = await getVismaSignAuthMethods();
    setAuthMethods(res.methods);
  };

  useEffect(() => {
    fetchAuthMethods();
  }, []);

  const goSignUrl = async (authMethod) => {
    setUserData('authMethod', authMethod);
    user.authMethod = authMethod;
    const invitation = await reserveStorageAndSignDocument(user);
    if (invitation) {
      window.location = invitation.url;
    }
  }

  const methodsList = authMethods.map((method) =>
    <li key={method.identifier} onClick={() => goSignUrl(method.identifier)}>
      <img alt={method.identifier} src={method.image}/>
      <p>{method.name}</p>
      <FontAwesomeIcon icon={faCaretRight} />
    </li>
  );

  return (
    <div className="sign-body">
      <h2>
        <FontAwesomeIcon icon={faFileSignature} />
        Siirry allekirjoittamaan vuokrasopimus pankkitunnuksilla / mobiilivarmenteella
      </h2>
      <ul>{ methodsList }</ul>
      <i className="info-label">Sähköinen allekirjoitus toteutettu Visma Signin palvelulla</i>
    </div>
  );
};

export default Sign;