import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#eeeeee',
      padding: 10
    },
    menuButton: {
      marginRight: 1
    },
    title: {
      flexGrow: 1,
      textAlign: 'left',
      color: '#646464',
      fontWeight: 'bold',
      fontFamily: 'Poppins, sans-serif',
      alignSelf: 'flex-end',
      letterSpacing: -1,
      fontSize: 18,
      paddingBottom: 0,
      transition: 'ease all .5s',
      '@media (max-width: 600px)': {
        fontSize: 16
      }
    },
    logo: {
      maxWidth: 250
    }
  })
);

const AppHeader = () => {
  const logo = process.env.PUBLIC_URL + '/Pienvarastokeskus-logo_RGB(Orange).png'
  const classes = useStyles();
  return (
    <AppBar classes={{root: classes.root}} position="static">
      <Toolbar>
        <Link href="https://www.pienvarastokeskus.fi" underline="none">
          <img src={logo} alt="logo" className={classes.logo} />
        </Link>
        <Typography 
          variant="h6" 
          className={classes.title}>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;