import { 
  SET_USER_DATA_ROW, 
  SET_MULTIPLE_ROWS_USER_DATA, 
  SET_SELECTED_STORAGE,
  SET_UTIL,
  SET_ERROR,
  REMOVE_ERRORS,
  SET_STORAGE_UTILS,
  DELETE_STORAGE,
  REMOVE_USER_DATA_ROW,
  REMOVE_USER_CREDENTIALS
} from './actionTypes';

export const setUserData = (name, value) => ({
  type: SET_USER_DATA_ROW,
  name,
  value
});

export const removeUserData = (key) => ({
  type: REMOVE_USER_DATA_ROW,
  key
});

export const removeUserCredentials = () => ({
  type: REMOVE_USER_CREDENTIALS
});

export const setMultipleRowsUserData = (rows) => ({
  type: SET_MULTIPLE_ROWS_USER_DATA,
  rows
});

export const setUtils = (util, value) => ({
  type: SET_UTIL,
  util,
  value
});

export const setError = (error) => ({
  type: SET_ERROR,
  error
});

export const removeErrors = () => ({
  type: REMOVE_ERRORS
});

export const setSelectedStorage = (storage) => ({
  type: SET_SELECTED_STORAGE,
  storage
});

export const setStorageUtils = (name, value, id) => ({
  type: SET_STORAGE_UTILS,
  name,
  value,
  id
});

export const removeStorage = (id) => ({
  type: DELETE_STORAGE,
  id: id
});